
import { defineComponent, onMounted, ref, computed, onBeforeMount } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { useRoute, useRouter } from 'vue-router'
import ApiService from "@/core/services/ApiService";
import { ElMessageBox } from "element-plus";
import CommonService from "@/core/services/CommonService";

export default defineComponent({
  name: "student-settings",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const id : number = +route.params.id; 
    const countries = ref([]);
    const studentStatuses = ref([]);
    const admissionCategories = ref([]);
    const sexTypes = ref([]);
    const formRef = ref<null | HTMLFormElement>(null);
    const agents = ref<any>([]);
    const advisors = ref<any>([]);
    const isPhotoUpdated = ref(false);

    const rules = ref({
      record_type: [
        {
          required: true,
          message: "Please select transaction type",
          trigger: "change",
        },
      ],
    });

    const getTypes = async() => {
      return new Promise((resolve, reject) => { 
        ApiService.setHeader();
        ApiService.get("student/types")
        .then(({ data }) => {
            countries.value = data.countries;
            studentStatuses.value = data.studentStatuses;
            admissionCategories.value = data.admissionCategories;
            sexTypes.value = data.sexTypes;
            resolve(data.data);
        })
        .catch(({ response }) => {
          reject('getTypes student error => '+response.data.errors);
          console.log('getTypes student error => '+response.data.errors);
        });
      });
    }

    const getAgents = async() =>{
      await new Promise((resolve, reject) => {  
        ApiService.setHeader();
        ApiService.get("agent")
              .then(({ data }) => {
                  agents.value = data.data;
                  if(profileDetails.value.agent_id == null){
                    profileDetails.value.agent_id = -1;
                  }

                  resolve(data);
              })
              .catch(({ response }) => {
                console.log('getAgents error => '+response.data.errors);
                reject(response.data.errors);
              });
      });
    }

    const getAdvisors = async() =>{
      await new Promise((resolve, reject) => {  
        ApiService.setHeader();
        ApiService.get("staff")
              .then(({ data }) => {
                  advisors.value = data.data;
                  if(profileDetails.value.advisor_id == null){
                    profileDetails.value.advisor_id = -1;
                  }
                  resolve(data);
              })
              .catch(({ response }) => {
                console.log('getAdvisors error => '+response.data.errors);
                reject(response.data.errors);
              });
      });
    }

    const profileDetails = computed(() => {
        return store.getters.currentStudentProfile.studentProfile;
    });

    const createStudent = async () => {
        isValidStudentId().then((isValid)=>{
            if(isValid){
                generateStudentId().then(() => {
                ApiService.setHeader();
                ApiService.setFormDataHeader();
                let formdata = filterData();
                ApiService.post("student", formdata as any)
                    .then(({data}) => {
                      Swal.fire({
                          text: "New student is created successfully",
                          icon: "success",
                          buttonsStyling: false,
                          confirmButtonText: "Ok, got it!",
                          customClass: {
                            confirmButton: "btn btn-primary",
                          },
                        })
                        .then(() => {
                            window.location.href=`/student/${data.student.id}/profile`;
                        });;
                        
                    })
                    .catch(({ response }) => {
                        console.log('createStudent error => '+response.data.errors);
                        Swal.fire({
                          text: "Failed to create the new student",
                          icon: "error",
                          buttonsStyling: false,
                          confirmButtonText: "Ok, got it!",
                          customClass: {
                            confirmButton: "btn btn-primary",
                          },
                        });
                    });
                });
            }
            else{
                Swal.fire({
                  text: "Student ID is duplicated. please enter the correct student ID",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
            }
        });
    }

    const generateStudentId = async() =>{
      if(profileDetails.value.status === "Active" && (profileDetails.value.student_id === undefined||profileDetails.value.student_id === null||profileDetails.value.student_id === '')){
          ApiService.setHeader();
          await ApiService.get("student/studentno/new")
                .then(({ data }) => {
                    profileDetails.value.student_id = data.data;
                })
                .catch(({ response }) => {
                  console.log('getTypes student error => '+response.data.errors);
                });
        }
    }

    const isValidStudentId = async()=> {
        return new Promise<boolean>((resolve, reject) => { 
            if(profileDetails.value.student_id){
                ApiService.setHeader();
                ApiService.get("student/"+profileDetails.value.id+"/studentno/"+profileDetails.value.student_id+"/isValid")
                    .then(({ data }) => {
                        resolve(data.isValid);
                    })
                    .catch(({ response }) => {
                      console.log('getTypes student error => '+response.data.errors);
                      reject(false);
                    });
            }
            else{
              resolve(true);
            }
        });
    }

    const filterData = () =>{
      if(profileDetails.value.agent_id == -1){
        profileDetails.value.agent_id = null;
        profileDetails.value.agent = null;
      }

      if(profileDetails.value.advisor_id == -1){
        profileDetails.value.advisor_id = null;
        profileDetails.value.advisor = null;
      }

      var formData = new FormData();

      if(profileDetails.value.photoFile){
        formData.append('file', profileDetails.value.photoFile);
      }

      delete profileDetails.value.photoFile;
      delete profileDetails.value.photoUrl;
      delete profileDetails.value.agent;
      formData.append("document", JSON.stringify(profileDetails.value));
      
      return formData;
    }

    const showPreview = (event) =>{
      isPhotoUpdated.value = true;
      profileDetails.value.photoUrl = URL.createObjectURL(event.target.files[0]);
      profileDetails.value.photoFile = event.target.files[0];
    }

    const updateStudent = async () => {
      isValidStudentId().then((isValid)=>{
        if(isValid){
          generateStudentId().then(() =>{
          ApiService.setHeader();
          ApiService.setFormDataHeader();
          ApiService.update("student", id, (filterData()) as any)
              .then(() => {
                Swal.fire({
                    text: "Updated successfully",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  });
              })
              .catch(({ response }) => {
                  console.log('updateStudent error => '+response.data.errors);
                  Swal.fire({
                    text: "Failed to create the new student",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  });
              });
          });
        }
        else{
           Swal.fire({
              text: "Student ID is duplicated. please enter the correct student ID",
               icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
        }
      });
    }

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          if(id == 0){
            createStudent();
          }
          else{
            updateStudent();
          }
        } else {
          Swal.fire({
            text: "Please fill out all required fields",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    const removeImage = () => {
      profileDetails.value.photoUrl = undefined;
      profileDetails.value.photo_id = undefined;
    };

    const deleteStudent = () =>{
      ElMessageBox.confirm('Are you sure to delete this student?')
                .then(() => {
                    ApiService.setHeader();
                    ApiService.delete("student/"+id)
                        .then(async () => {
                            router.push({name:"student-list"});
                        })
                        .catch(({ response }) => {
                            console.log('handleDelete error => '+response.data.errors);
                        });
                });
    }

    const acceptPhoneNumber = () => {
        profileDetails.value.phone = CommonService.getPhoneNumber(profileDetails.value.phone);
    }

    onBeforeMount(async() =>{
      await getTypes().then(async () => {
        getAgents();
        getAdvisors();
      });
    })

    onMounted(() => {
      setCurrentPageBreadcrumbs("Profile", ["Student"]);
    });

    return {
      submit,
      profileDetails,
      formRef,
      removeImage,
      rules,
      countries,
      studentStatuses,
      admissionCategories,
      sexTypes,
      agents,
      advisors,
      showPreview,
      deleteStudent,
      acceptPhoneNumber
    };
  },
});
